import * as React from 'react';

import {
  Modal,
  ModalContext,
  BackgroundAPIImage,
  Icon,
  ButtonLink,
  Context,
  BlockContent,
  Sections,
  ResponsiveBackgroundImage,
} from '../../global';
import style from './UpsellModal.module.css';
import {Text} from '../../typography';
import {Box, Column, Container, Row, Stack} from '../../layout';
import {PurchaseFragment} from '../../../types/api';
import {Button} from '../../forms/Button';
import {formatMoney} from '../../../utils/money';
import {useAddToCart} from '../../../hooks/useAddToCart';
import {Section} from '../../layouts/Section';

export const UpsellModal: React.FC = () => {
  const {site} = React.useContext(Context);
  const {activeProps} = React.useContext(ModalContext);
  const props: {
    upsellOffer: PurchaseFragment;
  } = React.useMemo(() => activeProps.upsell, [activeProps.upsell]);
  const {addToCart, loading} = useAddToCart();

  return (
    <Modal
      name="upsell"
      hideClose={true}
      noGradient
      Left={() => (
        <>
          {site?.upsellModal?.image ? (
            <ResponsiveBackgroundImage image={site.upsellModal.image} />
          ) : (
            <BackgroundAPIImage src="/images/plus-modal.png" doNotResize />
          )}
        </>
      )}
      Right={() => (
        <>
          <Section
            padding={{
              xs: 0,
              md: 80,
            }}
          >
            <Container maxWidth="sm">
              <Row>
                <Column>
                  <span className={style.label}>Special Offer</span>
                  <h2 className={style.title}>
                    <span>{'Mindfulness '}</span>
                    <Icon name="plus-gold" width={96} height={40} />
                  </h2>
                  <BlockContent
                    content={site?.upsellModal?.content}
                    background="white"
                  />

                  <Box
                    background="greyWarm"
                    radius="lg"
                    paddingY={16}
                    paddingX={20}
                    marginB={24}
                  >
                    <Stack direction="horizontal" justify="space-between">
                      <Stack direction="vertical">
                        <h3 className={style.priceTitle}>
                          Mindfulness{' '}
                          <Icon name="plus-gold" width={52} height={22} />
                        </h3>
                        <Text fontSize="sm" colour="grey5">
                          Annual membership
                        </Text>
                      </Stack>
                      <Stack direction="horizontal" items="center" space={12}>
                        {props?.upsellOffer?.price?.comparePriceUSD ? (
                          <>
                            <Text
                              fontSize="md2"
                              colour="grey5"
                              decoration="line-through"
                            >
                              {formatMoney(
                                  props.upsellOffer.price.comparePriceUSD,
                              )}
                            </Text>
                            <Text
                              fontSize="md2"
                              fontWeight="bold"
                              colour="highlight2"
                            >
                              {formatMoney(
                                  props?.upsellOffer?.price?.priceUSD || 0,
                              )}
                            </Text>
                          </>
                        ) : (
                          <Text fontSize="md2" fontWeight="bold">
                            {formatMoney(
                                props?.upsellOffer?.price?.priceUSD || 0,
                            )}
                          </Text>
                        )}
                      </Stack>
                    </Stack>
                  </Box>
                  <Row gap={8}>
                    <Column
                      spacing={8}
                      span={{
                        xs: 24,
                        md: 12,
                      }}
                    >
                      <ButtonLink
                        id="UpsellCheckout"
                        href="/checkout"
                        variant="secondary"
                        wide
                      >
                        Continue to Checkout
                      </ButtonLink>
                    </Column>
                    <Column
                      span={{
                        xs: 24,
                        md: 12,
                      }}
                    >
                      <Button
                        id="UpsellAddToCart"
                        loading={loading}
                        wide
                        onClick={async () => {
                          await addToCart(props.upsellOffer);
                        }}
                        type="button"
                      >
                        Add to Cart
                      </Button>
                    </Column>
                  </Row>
                </Column>
              </Row>
            </Container>
          </Section>
          <Sections sections={site?.upsellModal?.sections} />
        </>
      )}
    />
  );
};
